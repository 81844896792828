.active {
	background-color: #0c65d9c4 !important;
	color: #fff !important;
	.MuiSvgIcon-root {
		color: #fff !important;
	}
}

.swal2-container {
	z-index: 1200 !important;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
	background: rgba(0, 0, 0, 0.7);
}

.swal2-styled.swal2-confirm {
	background-color: #0c65d9c4;
}

.MuiChip-colorSuccess {
	color: #fff !important;
}
.MuiAutocomplete-root .MuiOutlinedInput-root {
	padding: 4px !important;
}

:focus-visible {
	outline: invert !important;
}
button:focus {
	outline: invert !important;
}

.capitalize {
	text-transform: capitalize;
}

.daterangepicker {
	color: #333 !important;
}

.rdt_Table {
	background-color: #ffffff00 !important;
}

/* width */
::-webkit-scrollbar {
	width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #ffffff00;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ffffff00;
}
